export const CONFIG = {
  jaeren: {
    dividendFactor: 0.0033,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av generalforsamlingen 10. april. Kalkulatoren beregner ut
            fra at du har vært kunde hele året. Nedre utbetalingsgrense er 100
            kroner. Dersom du har hatt mindre enn 30 000 kroner i lån og/eller
            innskudd, vil du for 2024 ikke kvalifisere til minimumsutbetalingen på
            100 kroner.`,
  },
  sandnes: {
    dividendFactor: 0.00335,
    minimumPayment: 0,
    year: 2024,
    disclaimerText: `Kalkulatoren beregner ut fra at du har vært kunde hele året. Har du ikke vært kunde i hele 2024,
            får du likevel kundeutbytte, men kalkulatoren vil da ikke gi deg riktig beløp.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-font": "#222",
      "--color-input-background": "#ffd600",
      "--color-slider-background": "#ffd600",
      "--color-slider-thumb": "#222",
      "--color-radio-background": "#ffd600",
      "--color-radio-dot": "#222",
    },
  },
  rogalandbm: {
    dividendFactor: 0.00335,
    minimumPayment: 0,
    year: 2024,
    isCorporateBanking: true,
    heading: "Regn ut hva din bedrift får i kundeutbytte",
    disclaimerText: `Kalkulatoren beregner ut fra at dere har vært bedriftskunde hele året. Har du ikke vært
            bedriftskunde i hele 2024, får du likevel kundeutbytte, men kalkulatoren vil da ikke gi deg riktig beløp.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-font": "#222",
      "--color-input-background": "#ffd600",
      "--color-slider-background": "#ffd600",
      "--color-slider-thumb": "#222",
      "--color-radio-background": "#ffd600",
      "--color-radio-dot": "#222",
    },
  },
  narvik: {
    dividendFactor: 0.0025,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av bankens generalforsamling i mars og utbetales i april 2025.
            Kalkulatoren beregner ut fra at du har vært kunde hele året. Nedre utbetalingsgrense
            er 100 kroner. Dersom du har hatt mindre enn 40 000 kroner i lån og/eller innskudd,
            vil du i 2024 ikke kvalifisere til minimumsutbetalingen på 100 kroner.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-font": "#fff",
      "--color-input-background": "#0065A4",
      "--color-slider-background": "#55BCE7",
      "--color-slider-thumb": "#0065A4",
      "--color-radio-background": "#55BCE7",
      "--color-radio-dot": "#0065A4",
    },
  },
  oslofjord: {
    dividendFactor: 0.0028,
    minimumPayment: 100,
    year: 2024,
    disclaimerText: `Utbyttesummen refererer til foreløpig overskuddsdisponering. Formelt
            vedtak fattes av representantskapet i banken hver vår. Kalkulatoren beregner ut
            fra at du har vært kunde hele året. Nedre utbetalingsgrense er 100 kroner.
            Dersom du har hatt mindre enn 40 000 kroner i lån og/eller innskudd, vil du i
            2024 ikke kvalifisere til minimumsutbetalingen på 100 kroner.`,
    style: {
      "--color-font-secondary": "#555",
      "--color-input-background": "#1B4650",
      "--color-slider-thumb": "#1B4650",
      "--color-radio-dot": "#1B4650",
    },
  },
};
